<template>
  <div v-if="model && model.cityId && model.cityId > 0">
    <img :src="imagePath" class="mapImage" @error="error" />
  </div>
</template>

<script>
import store from "../store/index";
export default {
  name: "traffic-map",
  props: ["data"],
  created() {
    if (!this.model.cityName) store.dispatch("setNextPlaylist");
    else this.startTimer();
  },
  data() {
    return {
      destroyed: false
    };
  },
  methods: {
    startTimer() {
      setTimeout(() => {
        if (!this.destroyed) store.dispatch("setNextPlaylist");
      }, 20000);
    },
    error() {
      store.dispatch("setNextPlaylist");
    }
  },
  computed: {
    model() {
      return this.$store.state;
    },
    imagePath() {
      return `${process.env.VUE_APP_BASE_CDN_URL}/Resources/Maps/${this.model.cityId}.jpg`;
    }
  },
  beforeDestroy() {
    this.destroyed = true;
  }
};
</script>

<style lang="scss" scoped>
.mapImage {
  width: 100%;
  height: 100vh;
}
</style>
